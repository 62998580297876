<template>
  <div class="manage-tree-export-page">
    <stand-out-block class="settings-item light">
      <h6 class="heading-6">Export</h6>
      <export-tree-container
        :tree-name="familyTreeDetailsState.name"
        :tree-id="familyTreeDetailsState.object_id"
      ></export-tree-container>
    </stand-out-block>
  </div>
</template>

<script>
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import {mapGetters} from 'vuex';

import ExportTreeContainer from '@/components/modules/familyTreeManage/manageTree/ExportTreeContainer';

export default {
  computed: {
    ...mapGetters(['familyTreeDetailsState']),
  },
  components: {StandOutBlock, ExportTreeContainer},
  name: 'ManageTreeExportPage',
};
</script>

<style scoped></style>
