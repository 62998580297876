<template>
  <div class="manage-tree-customize-page">
    <stand-out-block class="settings-item light">
      <h6 class="heading-6 no-margin">Tree Viewing Options</h6>
      <div class="text-sm">Affects your view of all family trees on My China Roots</div>
      <hr />
      <div class="inline-item">
        <div>Color person cards by gender</div>
        <switch-toggle :value="colorCodeGender" @toggle="saveColorCodeGender"></switch-toggle>
      </div>
    </stand-out-block>
  </div>
</template>

<script>
import SwitchToggle from '@common/elements/inputs/SwitchToggle';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['familyTreeDetailsState', 'familyTreeStartPersonIdState', 'familyTreeMapImageExistsState']),
    colorCodeGender() {
      return this.familyTreeDetailsState.preferences
        ? this.familyTreeDetailsState.preferences.color_code_gender
        : false;
    },
  },
  methods: {
    saveColorCodeGender(value) {
      this.$store.commit('mutateFamilyTreeDetailsState', {preferences: {color_code_gender: value}});
      this.$store
        .dispatch('changeUserInformationAction', {userId: 'me', color_code_gender: value})
        .then(() => {
          this.$toasted.success('Color persons cards by gender option updated');
          if (this.familyTreeMapImageExistsState) {
            this.$store.dispatch('fetchFamilyTreeMapAction', {
              familyTreeId: this.familyTreeDetailsState.object_id,
              colorCode: value ? '1' : '0',
              startPerson: this.familyTreeStartPersonIdState,
            });
          }
        })
        .catch(() => {
          this.$store.commit('mutateFamilyTreeDetailsState', {preferences: {color_code_gender: !value}});
          this.$toasted.error('Error updating the option');
        });
    },
  },
  components: {StandOutBlock, SwitchToggle},
  name: 'ManageTreeCustomizePage',
};
</script>

<style lang="scss" scoped>
.manage-tree-customize-page {
  .text-sm {
    margin: 5px 0 15px;
    color: $neutral-600;
  }
}
</style>
