<template>
  <div class="manage-all-trees-page">
    <div class="readable_content" v-if="familyTreeListLoadingState">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
    <div class="readable_content" v-else>
      <h6>Your Trees</h6>
      <stand-out-block class="tree-item light" v-for="item in ownedTrees" :key="item.object_id">
        <div class="tree-item-content">
          <div class="tree-data">
            <router-link :to="getManageTreeRoute(item)" class="tree-name">{{ item.name }}</router-link>
            <div v-if="isDefaultTree(item.object_id)" v-tooltip="defaultTreeTooltip" class="home-icon-container">
              <home-icon class="home-icon" :size="20" title=""></home-icon>
            </div>
          </div>
          <div class="tree-meta">
            <span class="persons-count"
              ><profile-icon :size="16" title="" />{{ item.persons_count }}
              {{ item.persons_count > 1 ? 'persons' : 'person' }}</span
            >
            <div class="added-date supplemental" v-tooltip="getAddDateTooltip(item)">
              <added-icon :size="20" title="" /> {{ getAddedDate(item) }}
            </div>
          </div>
        </div>
      </stand-out-block>

      <h6>Shared with you</h6>
      <stand-out-block class="tree-item light" v-for="item in sharedTrees" :key="item.object_id">
        <div class="tree-item-content">
          <div class="tree-data">
            <router-link :to="getManageTreeRoute(item)" class="tree-name">{{ item.name }}</router-link>
            <div v-if="item.is_editor" v-tooltip="editorTooltip" class="home-icon-container">
              <editor-icon class="home-icon" :size="20" title=""></editor-icon>
            </div>
            <div v-else v-tooltip="viewerTooltip" class="home-icon-container">
              <viewer-icon class="home-icon" :size="20" title=""></viewer-icon>
            </div>
          </div>
          <div class="tree-meta">
            <span class="persons-count"
              ><profile-icon :size="16" title="" />{{ item.persons_count }}
              {{ item.persons_count > 1 ? 'persons' : 'person' }}</span
            >
            <div class="added-date supplemental" v-tooltip="getAddDateTooltip(item)">
              <added-icon :size="20" title="" /> {{ getAddedDate(item) }}
            </div>
          </div>
        </div>
      </stand-out-block>

      <div class="add-trees">
        <div class="heading">
          <div class="bold">Add Tree</div>
        </div>
        <div class="link-item">
          <router-link :to="createTreeRoute"
            ><plus-icon :size="24"></plus-icon><span>Create New Tree</span></router-link
          >
        </div>
        <div class="link-item">
          <router-link :to="importGedcomRoute"
            ><import-icon :size="24"></import-icon><span>Import GEDCOM File</span></router-link
          >
          <div class="beta-badge">Beta</div>
        </div>
        <hr />
        <div class="disclaimer">
          <p>
            If you have an existing family tree made with another genealogy platform, you can import it here. Check your
            family tree building platform for an export function to get a GEDCOM file.
          </p>
          <p>
            Any tree you create on My China Roots and the data within will be private and not accessible to others
            (unless you choose to share it).
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import moment from 'moment/moment';
import ProfileIcon from 'vue-material-design-icons/Account';
import AddedIcon from 'vue-material-design-icons/ClockOutline';
import ViewerIcon from 'vue-material-design-icons/Eye';
import HomeIcon from 'vue-material-design-icons/Home';
import ImportIcon from 'vue-material-design-icons/Import';
import EditorIcon from 'vue-material-design-icons/Pencil';
import PlusIcon from 'vue-material-design-icons/Plus';
import {mapGetters} from 'vuex';

export default {
  metaInfo: {
    title: 'Manage Trees',
  },
  created() {
    this.$store.dispatch('fetchFamilyTreeListAction', {
      fields: 'object_id,name,persons_count,created_at,is_owner,is_editor',
    });
  },
  computed: {
    ...mapGetters(['familyTreeListState', 'familyTreeListLoadingState']),
    meta() {
      return {
        total_count: this.familyTreeListState.length,
        offset: 0,
        limit: this.familyTreeListState.length,
      };
    },
    createTreeRoute() {
      return {name: 'familytree-create'};
    },
    importGedcomRoute() {
      return {name: 'familytree-import-gedcom'};
    },
    defaultTreeTooltip() {
      return {content: 'Your default tree', placement: 'top'};
    },
    editorTooltip() {
      return {content: 'Editor access', placement: 'top'};
    },
    viewerTooltip() {
      return {content: 'Viewer access', placement: 'top'};
    },
    ownedTrees() {
      return this.familyTreeListState.filter(tree => tree.is_owner);
    },
    sharedTrees() {
      return this.familyTreeListState.filter(tree => !tree.is_owner);
    },
  },
  methods: {
    getManageTreeRoute(item) {
      return {
        name: 'familytree-manage',
        params: {
          id: item.object_id,
        },
      };
    },
    getAddedDate(item) {
      return moment(item.created_at).format('MMM DD, YYYY');
    },
    isDefaultTree(id) {
      return id === this.$store.getters.userFamilyTreeIdState;
    },
    getAddDateTooltip(item) {
      const dateTime = moment(item.created_at).format('hh:mm MMM DD, YYYY');
      return {content: `Created at ${dateTime}`, placement: 'top'};
    },
  },
  components: {
    StandOutBlock,
    PlusIcon,
    ImportIcon,
    AddedIcon,
    HomeIcon,
    ProfileIcon,
    ViewerIcon,
    EditorIcon,
  },
  name: 'ManageAllTreesPage',
};
</script>

<style lang="scss" scoped>
.manage-all-trees-page {
  h6 {
    margin-bottom: 12px;
    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  .tree-item {
    margin-bottom: 20px;
    padding: 15px 24px;

    .tree-item-content {
      display: flex;
      justify-content: space-between;
      .tree-data {
        display: flex;
        align-items: center;
        .home-icon-container {
          display: flex;
          align-items: center;
          color: $neutral-500;
          margin-top: -2px;
          margin-right: 5px;
        }
      }
      .tree-meta {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 14px;
      }
    }

    .tree-name {
      font-weight: bold;
      margin-right: 7px;
    }
    .persons-count {
      display: flex;
      align-items: center;
      color: $neutral-500;
      margin-bottom: 5px;
      .material-design-icon {
        margin-top: -1px;
        margin-right: 3px;
      }
    }
    .added-date {
      display: flex;
      align-items: center;
      .material-design-icon {
        margin-right: 5px;
        margin-top: -2px;
        vertical-align: middle;
      }
    }
  }

  .add-trees {
    margin-top: 40px;
  }

  .link-item {
    margin-top: 10px;
    display: flex;
    a {
      display: flex;
      align-items: center;
      flex-grow: 0;
    }
  }

  hr {
    margin: 25px 0;
  }

  .disclaimer {
    color: $supplemental-text-color;
  }

  .beta-badge {
    color: white;
    background-color: $mcr-red;
    padding: 2px 6px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: normal;
    margin-left: 5px;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .tree-item {
      .tree-item-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .tree-data {
          display: flex;
          flex-wrap: wrap;
        }
        .tree-meta {
          align-items: flex-start;
          flex-direction: row;
          margin-top: 5px;
          > * {
            margin-right: 10px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
