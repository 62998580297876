<template>
  <stand-out-block class="settings-navigation light">
    <router-link :to="detailsRoute" class="settings-navigation-item"
      ><tree-icon class="item-icon tree-icon" :size="20" /><span class="label">Tree Details</span
      ><arrow-icon class="arrow-icon" :size="25"
    /></router-link>
    <router-link :to="privacyRoute" class="settings-navigation-item"
      ><privacy-icon class="item-icon" :size="22" /><span class="label">Privacy</span
      ><arrow-icon class="arrow-icon" :size="25"
    /></router-link>
    <router-link
      :to="sharingRoute"
      class="settings-navigation-item"
      :class="{disabled: !familyTreeDetailsState.is_owner}"
      ><sharing-icon class="item-icon" :size="22" /><span class="label">Sharing</span
      ><arrow-icon class="arrow-icon" :size="25" />
    </router-link>
    <router-link
      :to="exportRoute"
      class="settings-navigation-item"
      :class="{disabled: !familyTreeDetailsState.is_owner}"
      ><export-icon class="item-icon" :size="22" /><span class="label">Export</span
      ><arrow-icon class="arrow-icon" :size="25"
    /></router-link>
    <router-link :to="customizeRoute" class="settings-navigation-item"
      ><customize-icon class="item-icon" :size="22" /><span class="label">Customize Tree</span
      ><arrow-icon class="arrow-icon" :size="25"
    /></router-link>
  </stand-out-block>
</template>

<script>
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import SharingIcon from 'vue-material-design-icons/AccountMultipleOutline';
import ArrowIcon from 'vue-material-design-icons/ChevronRight';
import ExportIcon from 'vue-material-design-icons/Download';
import PrivacyIcon from 'vue-material-design-icons/LockOutline';
import CustomizeIcon from 'vue-material-design-icons/PencilRuler';
import TreeIcon from 'vue-material-design-icons/Sitemap';
import {mapGetters} from 'vuex';

export default {
  data() {
    const id = this.$route.params.id;
    const params = {id};
    return {
      detailsRoute: {name: 'familytree-manage-details', params},
      privacyRoute: {name: 'familytree-manage-privacy', params},
      sharingRoute: {name: 'familytree-manage-sharing', params},
      exportRoute: {name: 'familytree-manage-export', params},
      customizeRoute: {name: 'familytree-manage-customize', params},
    };
  },
  computed: {
    ...mapGetters(['familyTreeDetailsState']),
  },
  components: {StandOutBlock, TreeIcon, PrivacyIcon, ArrowIcon, ExportIcon, CustomizeIcon, SharingIcon},
  name: 'SettingsNavigation',
};
</script>

<style lang="scss" scoped>
.settings-navigation {
  padding: 12px;
  display: flex;
  flex-direction: column;
  min-width: 252px;
  border-radius: 4px;

  .settings-navigation-item {
    display: flex;
    align-items: center;
    padding: 8px;
    color: $text-color;
    border-radius: 4px;

    .item-icon.tree-icon {
      margin-top: -2px;
    }
    .label {
      margin-left: 10px;
    }
    .arrow-icon {
      margin-left: auto;
    }

    &.disabled {
      color: $neutral-400;
      pointer-events: none;
    }

    &:hover {
      text-decoration: none;
    }

    &:not(:first-child) {
      margin-top: 12px;
    }
  }
  .settings-navigation-item.router-link-exact-active {
    font-weight: bold;
    background: $neutral-100;
  }
}
</style>
