<template>
  <div class="manage-tree-privacy-page">
    <stand-out-block class="settings-item light">
      <h6 class="heading-6">Privacy Settings</h6>
      <div class="tree-privacy-setting-option">
        <input
          type="radio"
          :disabled="!allowEdit"
          :id="inputPrivateId"
          :name="inputName"
          :checked="!isPublic"
          :value="!isPublic"
          @change="updateFamilyTree(false)"
        />
        <label class="description" :for="inputPrivateId"
          ><span class="bold text-lg">Private</span>
          <span class="text-xs">Only you and the people you invite will be able to see the tree.</span></label
        >
      </div>
      <div class="tree-privacy-setting-option">
        <input
          type="radio"
          :disabled="!allowEdit"
          :id="inputPublicId"
          :name="inputName"
          :checked="isPublic"
          :value="isPublic"
          @change="updateFamilyTree(true)"
        />
        <label class="description" :for="inputPublicId"
          ><span class="bold text-lg">Public</span>
          <span class="text-xs">Anyone with the share link can view this tree.</span></label
        >
      </div>

      <transition name="fade">
        <div class="family-tree-share-link" v-if="isPublic">
          <tree-share-link :tree-id="objectId"></tree-share-link>
        </div>
      </transition>
    </stand-out-block>
  </div>
</template>

<script>
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import {mapGetters} from 'vuex';

import TreeShareLink from '@/components/common/tree/TreeShareLink';

export default {
  computed: {
    ...mapGetters(['familyTreeDetailsState']),
    objectId() {
      return this.familyTreeDetailsState.object_id;
    },
    isPublic() {
      return this.familyTreeDetailsState.is_public;
    },
    inputPrivateId() {
      return `tree-privacy-private-${this.objectId}`;
    },
    inputPublicId() {
      return `tree-privacy-public-${this.objectId}`;
    },
    inputName() {
      return `tree-privacy-radio-${this.objectId}`;
    },
    allowEdit() {
      return this.familyTreeDetailsState.is_owner;
    },
  },
  methods: {
    updateFamilyTree(value) {
      this.$store.dispatch('updateFamilyTreeAction', {id: this.objectId, is_public: value}).then(response => {
        this.onUpdated(response);
      });
    },
    onUpdated(response) {
      this.$toasted.success('Privacy settings updated');
      this.$store.commit('mutateFamilyTreeDetailsState', {is_public: response.is_public});
    },
  },
  components: {TreeShareLink, StandOutBlock},
  name: 'ManageTreeDetailsPage',
};
</script>

<style scoped lang="scss">
.manage-tree-privacy-page {
  .heading-6 + .tree-privacy-setting-option {
    margin-top: 20px;
  }
  .tree-privacy-setting-option {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    input[type='radio'] {
      margin: 0;
    }
    .description {
      margin-left: 20px;
      display: flex;
      flex-direction: column;

      .text-xs {
        opacity: 0.7;
      }
    }
  }
}
</style>
