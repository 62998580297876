<template>
  <div class="manage-tree-sharing-page">
    <stand-out-block class="settings-item light">
      <h6 class="heading-6 no-margin">Invite collaborators</h6>
      <div class="text-sm subtitle">You can invite your family members to collaborate on your family tree project</div>
      <tree-invite-input :tree-id="familyTreeDetailsState.object_id" @invited="onMembersInvited"></tree-invite-input>
      <div class="privacy-container text-xs" v-if="isTreePublic">
        <div class="icon-container"><lock-open-icon :size="20" /></div>
        <div>
          <div class="bold">Public</div>
          <div>Anyone with the share link can view this tree.</div>
        </div>
      </div>
      <div class="privacy-container text-xs" v-else>
        <div class="icon-container"><lock-icon :size="20" /></div>
        <div>
          <div class="bold">Private</div>
          <div>Only you and the people you invite will be able to see the tree.</div>
        </div>
      </div>
      <tree-share-link v-if="isTreePublic" :tree-id="familyTreeDetailsState.object_id"></tree-share-link>
    </stand-out-block>
    <stand-out-block class="settings-item light users-list" v-if="familyTreeDetailsState.tree_members.length">
      <div class="heading-container">
        <h6 class="heading-6">User List</h6>
        <bounce-loading v-if="userListUpdateLoading" class="dark"></bounce-loading>
      </div>
      <div class="user-item text-sm" v-for="user in familyTreeDetailsState.tree_members" :key="user.email">
        <div class="names">
          <div>{{ user.full_name }}</div>
          <div class="email">{{ user.email }}</div>
        </div>

        <tree-member-role-select
          :tree-id="familyTreeDetailsState.object_id"
          :email="user.email"
          :is-editor="user.is_editor"
          @pre-role-change="onPreRoleChange"
          @deleted="onRoleDeleted"
        ></tree-member-role-select>
      </div>
    </stand-out-block>
  </div>
</template>

<script>
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';
import LockOpenIcon from 'vue-material-design-icons/LockOpenVariantOutline';
import LockIcon from 'vue-material-design-icons/LockOutline';
import {mapGetters} from 'vuex';

import TreeInviteInput from '@/components/common/tree/TreeInviteInput';
import TreeMemberRoleSelect from '@/components/common/tree/TreeMemberRoleSelect';
import TreeShareLink from '@/components/common/tree/TreeShareLink';

export default {
  computed: {
    ...mapGetters([
      'familyTreeDetailsState',
      'familyTreeEditMemberLoadingState',
      'familyTreeDeleteMemberLoadingState',
      'familyTreeAddMembersLoadingState',
    ]),
    userListUpdateLoading() {
      const isEditing = Object.values(this.familyTreeEditMemberLoadingState).some(value => value);
      const isDeleting = Object.values(this.familyTreeDeleteMemberLoadingState).some(value => value);
      return isEditing || isDeleting;
    },
    familyTreeRoute() {
      return {name: 'familytree-details', params: {id: this.familyTreeDetailsState.object_id}};
    },
    isTreePublic() {
      return this.familyTreeDetailsState.is_public;
    },
  },
  methods: {
    onMembersInvited({list}) {
      this.$store.commit('mutateFamilyTreeDetailsState', {tree_members: list});
    },
    onPreRoleChange({email, is_editor}) {
      const newTreeMembers = this.familyTreeDetailsState.tree_members.map(m =>
        m.email === email ? {...m, is_editor} : m
      );
      this.$store.commit('mutateFamilyTreeDetailsState', {tree_members: newTreeMembers});
    },
    onRoleDeleted({email}) {
      const newTreeMembers = this.familyTreeDetailsState.tree_members.filter(m => m.email !== email);
      this.$store.commit('mutateFamilyTreeDetailsState', {tree_members: newTreeMembers});
    },
  },
  components: {
    TreeMemberRoleSelect,
    TreeShareLink,
    BounceLoading,
    TreeInviteInput,
    StandOutBlock,
    LockIcon,
    LockOpenIcon,
  },
  name: 'ManageTreeSharingPage',
};
</script>

<style lang="scss" scoped>
.manage-tree-sharing-page {
  .subtitle {
    margin: 5px 0 15px;
    color: $neutral-600;
  }
  .heading-container {
    display: flex;
  }
  .bounce-loading {
    margin: 0 0 0 auto;
  }

  .privacy-container {
    display: flex;
    align-items: center;
    margin: 32px 0 0 0;
    .icon-container {
      display: flex;
      margin-right: 10px;
    }
  }

  .user-item {
    padding: 14px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .names {
      margin-right: 10px;
    }

    .email {
      word-break: break-word;
      color: $neutral-500;
    }

    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      padding-bottom: 0;
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid $neutral-200;
    }
  }

  .tree-share-link {
    margin-top: 12px;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .input-container {
      flex-direction: column;
      .mcr-button {
        margin: 10px 0 0 0;
        width: 100%;
      }
    }
  }
}
</style>
