<template>
  <modal
    name="delete-tree-modal"
    classes="clear_modal white_modal delete-tree-modal"
    class="mobile_bottom"
    :scrollable="true"
    height="auto"
  >
    <div class="alert-modal-content">
      <h5>
        Are you sure you want to delete <span class="highlighted">{{ treeName }}</span
        >?
      </h5>

      <div class="description">
        All {{ personsCount }} persons and {{ assetsCount }} photos and files associated with this tree will also be
        deleted.
      </div>
      <div>
        To delete this tree, type "<b>{{ treeName }}</b
        >" into the input below
      </div>
      <div class="input-container">
        <input @input="changeInputValue" class="input" />
      </div>
      <div class="buttons">
        <mcr-button class="white" :disabled="loading" @click="closeModal">Cancel</mcr-button>
        <mcr-button class="delete-button" :disabled="isDeleteDisabled" :loading="loading" @click="deleteTree"
          >Delete</mcr-button
        >
      </div>
    </div>
  </modal>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';

export default {
  props: {
    treeName: String,
    treeId: Number,
    personsCount: Number,
    assetsCount: Number,
  },
  data() {
    return {
      inputValue: '',
      loading: false,
    };
  },
  computed: {
    isDeleteDisabled() {
      return this.inputValue !== this.treeName;
    },
  },
  methods: {
    closeModal() {
      this.$modal.hide('delete-tree-modal');
    },
    changeInputValue(event) {
      this.inputValue = event.target.value;
    },
    deleteTree() {
      this.loading = true;
      this.$store
        .dispatch('deleteFamilyTreeAction', {id: this.treeId})
        .then(response => {
          this.$toasted.success(`${this.treeName} was deleted.`);
          if (this.$store.getters.activeFamilyTreeIdState == this.treeId) {
            this.$store.commit('clearActiveFamilyTreeIdState');
          }
          if (response.member_family_tree_id) {
            this.$store.commit('setUserFamilyTreeIdState', response.member_family_tree_id);
          }
          this.$router.push({name: 'familytree-manage-all'});
        })
        .catch(() => {
          this.$toasted.error('Something went wrong during tree deletion. Please try again later.');
          this.loading = false;
        });
    },
  },
  components: {McrButton},
  name: 'DeleteTreeModal',
};
</script>

<style lang="scss" scoped>
.delete-tree-modal {
  h5 {
    margin-bottom: 0;
  }

  .input-container {
    margin-top: 16px;
    input {
      width: 100%;
    }
  }

  .highlighted {
    color: $active-color;
  }

  .description {
    margin: 20px 0 5px;
  }

  .delete-button {
    min-width: 150px;
  }
}
</style>
